import Component from '../component';
import PhoneField from './phone-field';
import SchoolField from './school-field';

export default class ArrayField extends Component {
  constructor(element) {
    super(...arguments);

    this.$element = $(element);
    this.counter = this.$element.find('.arrayField-item').length - 1;

    this.$formElement = this.$element.closest('form');
    this.$templateElement = this.$element.find('.arrayField-template');

    this.updateButtonsVisibility();
    this.bindEvents();
  }

  bindEvents() {
    this.handleDuplicateClick = this.handleDuplicateClick.bind(this);
    this.handleRemoveClick = this.handleRemoveClick.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);

    this.$element.on(
      'click.ArrayField',
      '.arrayField-duplicateButton',
      this.handleDuplicateClick
    );
    this.$element.on(
      'click.ArrayField',
      '.arrayField-removeButton',
      this.handleRemoveClick
    );
    this.$formElement.on('submit.ArrayField', this.handleFormSubmit);
  }

  unbindEvents() {
    this.$element.off(
      'click.ArrayField',
      '.arrayField-duplicateButton',
      this.handleDuplicateClick
    );
    this.$element.off(
      'click.ArrayField',
      '.arrayField-removeButton',
      this.handleRemoveClick
    );
    this.$formElement.off('submit.ArrayField', this.handleFormSubmit);
  }

  duplicateTemplate() {
    const newHTML = this.$templateElement.clone().html();
    this.counter++;
    this.$element.append(newHTML.replace(/__index__/g, this.counter));

    this.duplicateCallback(this.$element.children().last());
  }

  duplicateCallback($duplicatedElement) {
    if (
      $duplicatedElement[0] &&
      $duplicatedElement[0].classList.contains('phoneField')
    ) {
      PhoneField.initialize($duplicatedElement);
    }
    SchoolField.initialize($duplicatedElement.find('.schoolField'));
  }

  updateButtonsVisibility() {
    const $items = this.$element.children('.arrayField-item');

    $items.find('.arrayField-duplicateButton').hide();
    $items.last().find('.arrayField-duplicateButton').show();

    if ($items.length === 1) {
      $items.find('.arrayField-removeButton').hide();
    } else {
      $items.find('.arrayField-removeButton').show();
    }
  }

  handleDuplicateClick(event) {
    this.duplicateTemplate();
    this.updateButtonsVisibility();

    event.preventDefault();
  }

  handleRemoveClick(event) {
    $(event.target).closest('.arrayField-item').remove();
    this.updateButtonsVisibility();

    event.preventDefault();
  }

  handleFormSubmit() {
    this.$templateElement.remove();
  }
}
