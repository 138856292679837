import TypeaheadInput from '../../shared/components/typeahead-input';
import StringHelper from '../../helpers/string-helper';

export default class SchoolField extends TypeaheadInput {
  constructor(element) {
    const $element = $(element);
    const $input = $element.find('.schoolField-input');

    super($input, {
      autoselect: false,
      hint: false,
      highlight: true,
      minLength: 3,
    });

    this.$parentElement = $element;
    this.$searchInput = $input;
    this.$schoolPreview = $element.find('.schoolField-choice');
    this.$schoolResetButton = $element.find('.schoolField-choice-removal-icon');
    this.$schoolNameInputElement = $element.find(
      '.schoolField-schoolNameInput'
    );
    this.$idInputElement = $element.find('.schoolField-idInput');
    this.schoolTemplate = $element
      .find('.schoolField-suggestionTemplate')
      .html();

    this.currentValue = this.$searchInput.typeahead('val');
    this.allowFreeText = this.$typeaheadInput.data('allow-free-text');

    this.bindAdditionalEvents();
  }

  bindAdditionalEvents() {
    this.$schoolResetButton.on(
      'click.SchoolField',
      this.handleSchoolRemoval.bind(this)
    );
    if (this.allowFreeText)
      this.$searchInput.on(
        'input.SchoolField',
        this.handleTypeaheadChange.bind(this)
      );
  }

  unbindEvents() {
    this.$schoolResetButton.off('click.SchoolField', this.handleSchoolRemoval);
    if (this.allowFreeText)
      this.$searchInput.off('input.SchoolField', this.handleTypeaheadChange);
  }

  handleSchoolRemoval() {
    this.$schoolNameInputElement.val(null);
    this.$idInputElement.val(null);
    this.$searchInput.removeClass('hidden');
    this.$parentElement.removeClass('form-group--schoolField-hidden');
    this.$schoolPreview.addClass('hidden');
  }

  handleTypeaheadSelect(_event, school) {
    // Because Safari on iOS call the "close" event before the "select" event,
    // we need to debounce the cancel the cleanFieldTimeout on a "select" event.
    this.cleanFieldTimeout = null;
    this.currentValue = school.name;
    this.$idInputElement.val(school.id);
  }

  handleTypeaheadClose() {
    // Because Safari on iOS call the "close" event before the "select" event,
    // we need to debounce the cleanFieldTimeout and cancel it if we are in a "select" event.
    this.cleanFieldTimeout = setTimeout(() => {
      const typedValue = this.$searchInput.typeahead('val');

      if (typedValue)
        return this.$searchInput.typeahead('val', this.currentValue);

      this._clearValue();
    }, 100);
  }

  handleTypeaheadChange(event) {
    this.$schoolNameInputElement.val(event.target.value);
    this.currentValue = event.target.value;
    this.$idInputElement.val(null);
  }

  getTypeaheadSources() {
    const url = this.$typeaheadInput.data('url');
    return [this.buildSchoolsSource(url)];
  }

  /* eslint-disable quote-props */
  buildSchoolsSource(url) {
    return {
      name: 'schools',
      displayKey: 'name',
      limit: 5,
      source: this.createTypeaheadSource(url, (response) => {
        return response.schools.map((school) => ({
          id: school.id,
          name: school.name,
        }));
      }),
      templates: {
        suggestion: (item) => {
          return StringHelper.replaceTokens(this.schoolTemplate, {
            __name__: item.name,
          });
        },
      },
    };
  }
  /* eslint-enable quote-props */

  _clearValue() {
    this.currentValue = '';
    this.$idInputElement.val(null);
    this.$searchInput.typeahead('val', null);
  }
}
