import Component from '../component';

export default class BirthDatePicker extends Component {
  constructor(element) {
    super(...arguments);

    this.$element = $(element);
    this.$birthDateGroup = this.$element.parents('.lobbyForm-group-birthdate');
    this.$form = this.$element.parents('form');
    this.$yearSelector = this.$birthDateGroup.find('#user_born_on_1i');
    this.$monthSelector = this.$birthDateGroup.find('#user_born_on_2i');
    this.$daySelector = this.$birthDateGroup.find('#user_born_on_3i');

    this.bindEvents();

    this.validateSelectedDate();
  }

  bindEvents() {
    this.handleChange = this.handleChange.bind(this);

    this.$element.on('change.BirthDatePicker', this.handleChange);
  }

  unbindEvents() {
    this.$element.off('change.BirthDatePicker', this.handleChange);
  }

  handleChange(event) {
    if (event.target.options.selectedIndex === 0) return;

    this.validateSelectedDate();
  }

  validateSelectedDate() {
    if (this.isValidDateSelected()) {
      this.adjustFormForBirthDate();
    } else {
      // fallback to minor
      this.enableMinorMode();
    }
  }

  enableMinorMode() {
    this.showMinorVisibleRows();
    this.hideMajorVisibleRows();

    this.showMinorRequiredRows();
    this.hideMajorRequiredRows();
  }

  enableMajorMode() {
    this.hideMinorVisibleRows();
    this.showMajorVisibleRows();

    this.hideMinorRequiredRows();
    this.showMajorRequiredRows();
  }

  hideMinorVisibleRows() {
    this.$form.find('.lobbyForm-row--minor-visible').addClass('hidden');
  }

  hideMajorVisibleRows() {
    this.$form.find('.lobbyForm-row--major-visible').addClass('hidden');
  }

  showMinorVisibleRows() {
    this.$form.find('.lobbyForm-row--minor-visible').removeClass('hidden');
  }

  showMajorVisibleRows() {
    this.$form.find('.lobbyForm-row--major-visible').removeClass('hidden');
  }

  hideMinorRequiredRows() {
    this.$form
      .find('.lobbyForm-row--minor-required')
      .find('.userProfileForm-control')
      .removeClass('userProfileForm-control--required');
  }

  showMinorRequiredRows() {
    this.$form
      .find('.lobbyForm-row--minor-required')
      .find('.userProfileForm-control')
      .addClass('userProfileForm-control--required');
  }

  hideMajorRequiredRows() {
    this.$form
      .find('.lobbyForm-row--major-required')
      .find('.userProfileForm-control')
      .removeClass('userProfileForm-control--required');
  }

  showMajorRequiredRows() {
    this.$form
      .find('.lobbyForm-row--major-required')
      .find('.userProfileForm-control')
      .addClass('userProfileForm-control--required');
  }

  isBirthDateMinor() {
    const majorAge = 18;
    const secondsInYear = 31557600000;

    const today = new Date();
    const birthYear = this.$yearSelector.val();
    const birthMonth = this.$monthSelector.val();
    const birthDay = this.$daySelector.val();

    const birthDate = new Date(birthYear, birthMonth - 1, birthDay);
    const age = (today - birthDate) / secondsInYear;

    return age < majorAge;
  }

  adjustFormForBirthDate() {
    if (this.isBirthDateMinor()) {
      this.enableMinorMode();
    } else {
      this.enableMajorMode();
    }
  }

  isValidDateSelected() {
    const vals = this.$form
      .find('.lobbyForm-birth-date-picker')
      .map((_, element) => $(element).val() !== '')
      .toArray();

    return !vals.includes(false);
  }
}
