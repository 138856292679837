import Component from '../component';

const OTHER_OCCUPATION_NAME = 'Autre';

export default class OtherOccupationField extends Component {
  constructor(element) {
    super(...arguments);

    this.$element = $(element);
    this.$name = this.$element.find(':selected').attr('name');

    this.bindEvents();

    this.manageOtherOccupationField();
    this.manageTeacherOrganizationRequirement();
  }

  bindEvents() {
    this.handleChange = this.handleChange.bind(this);

    this.$element.on('change.OtherOccupationField', this.handleChange);
  }

  unbindEvents() {
    this.$element.off('change.OtherOccupationField', this.handleChange);
  }

  manageOtherOccupationField() {
    const method = this.$name === OTHER_OCCUPATION_NAME ? 'show' : 'hide';

    $('.other-occupation-field')[method]();
  }

  manageTeacherOrganizationRequirement() {
    $('.form-teacherOrganization-label').toggleClass(
      'form-label--required',
      this.$name !== OTHER_OCCUPATION_NAME
    );
  }

  handleChange() {
    this.$name = this.$element.find(':selected').attr('name');

    this.manageOtherOccupationField();
    this.manageTeacherOrganizationRequirement();
  }
}
