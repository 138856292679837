import Component from '../component';
import OrganizationField from './organization-field';

export default class ArrayFieldCocoon extends Component {
  constructor(element) {
    super(...arguments);

    this.$element = $(element);

    this.$duplicateButton = this.$element.find('.arrayField-duplicateButton');

    this.appendFirstItem();
    this.refreshButtonPositions();
    this.bindEvents();
  }

  bindEvents() {
    this.handleAfterInsert = this.handleAfterInsert.bind(this);
    this.handleBeforeRemove = this.handleBeforeRemove.bind(this);
    this.handleAfterRemove = this.handleAfterRemove.bind(this);

    this.$element.on(
      'cocoon:after-insert.ArrayFieldCocoon',
      this.handleAfterInsert
    );
    this.$element.on(
      'cocoon:before-remove.ArrayFieldCocoon',
      this.handleBeforeRemove
    );
    this.$element.on(
      'cocoon:after-remove.ArrayFieldCocoon',
      this.handleAfterRemove
    );
  }

  unbindEvents() {
    this.$element.off(
      'cocoon:after-insert.ArrayFieldCocoon',
      this.handleAfterInsert
    );
    this.$element.off(
      'cocoon:before-remove.ArrayFieldCocoon',
      this.handleBeforeRemove
    );
    this.$element.off(
      'cocoon:after-remove.ArrayFieldCocoon',
      this.handleAfterRemove
    );

    if (this.organizationFields) {
      this.organizationFields.forEach((field) => field.unbindEvents());
    }
  }

  appendFirstItem() {
    if (!this.currentItems().length) {
      this.$duplicateButton.click();
    }
  }

  handleAfterInsert() {
    this.refreshButtonPositions();

    const $organizationFields = this.$element.find('.organizationField');

    if ($organizationFields.length) {
      this.organizationFields = OrganizationField.initialize(
        $organizationFields
      );
    }
  }

  handleBeforeRemove() {
    this.refreshButtonPositions();
  }

  handleAfterRemove() {
    this.refreshButtonPositions();
  }

  refreshButtonPositions() {
    this.moveDuplicateButtonToFirstItem();
    this.placeDuplicateButton();

    if (this.currentItems().length === 1) {
      this.hideRemoveLinks();
    } else {
      this.showRemoveLinks();
    }
  }

  moveDuplicateButtonToFirstItem() {
    const $buttons = this.currentItems().first().find('.arrayField-buttons');

    $buttons.append(this.$duplicateButton);
    this.$duplicateButton.hide();
  }

  placeDuplicateButton() {
    const $buttons = this.currentItems().last().find('.arrayField-buttons');

    $buttons.append(this.$duplicateButton);
    this.$duplicateButton.show();
  }

  showRemoveLinks() {
    this.currentItems().find('.arrayField-removeButton').show();
  }

  hideRemoveLinks() {
    this.currentItems().find('.arrayField-removeButton').hide();
  }

  currentItems() {
    return this.$element.find('.arrayField-item:visible');
  }
}
