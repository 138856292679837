import Component from '../component';

export default class DropdownSelect extends Component {
  constructor(element) {
    super(...arguments);

    this.$selectElement = $(element);
    this.buildComponentHTML();
  }

  buildComponentHTML() {
    this.$selectElement
      .removeClass('dropdownSelect')
      .addClass('dropdownSelect-select');

    this.$selectElement.wrap('<div class="dropdownSelect"></div>');
    this.$element = this.$selectElement.parent();
    this.$element.append(
      '<div class="dropdownSelect-caret"><span class="icon-arrow-down"></span></div>'
    );
  }
}
