import Component from '../component';

export default class PhoneField extends Component {
  constructor(element) {
    super(...arguments);

    this.$element = $(element);

    this.updateSmsCommunicationsVisibility();
    this.bindEvents();
  }

  bindEvents() {
    this.handleTypeSelect = this.handleTypeSelect.bind(this);

    this.$element.on(
      'change.PhoneField',
      '.phoneField-type',
      this.handleTypeSelect
    );
  }

  unbindEvents() {
    this.$element.off(
      'change.PhoneField',
      '.phoneField-type',
      this.handleTypeSelect
    );
  }

  handleTypeSelect(event) {
    this.updateSmsCommunicationsVisibility();

    event.preventDefault();
  }

  updateSmsCommunicationsVisibility() {
    if (this.$element.find('.phoneField-type').val() === 'cell') {
      this.$element.find('.phoneField-smsCommunications').show();
    } else {
      this.$element.find('.phoneField-smsCommunications').hide();
    }
  }
}
