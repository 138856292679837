export default class Component {
  static initialize($elements, opts) {
    // Sometimes we want to pass a collection of elements.
    // When we can’t have that, we’ll pass a string with a
    // CSS selector to build a collection and then use it.
    if (typeof $elements == 'string') {
      $elements = $($elements);
    }

    const instances = $elements
      .map((_index, element) => {
        return new this(element, opts);
      })
      .toArray();

    this.instances = this.instances
      ? [...this.instances, ...instances]
      : [...instances];

    return instances;
  }

  static unbindEvents() {
    const instances = Array.isArray(this.instances) ? this.instances : [];

    instances.forEach((instance) => {
      instance.unbindEvents();
    });

    this.instances = [];
  }

  unbindEvents() {
    // Subclasses must override this method
  }
}
