/* eslint-disable no-console */
import Component from '../component';

export default class RegionDropdown extends Component {
  constructor(element) {
    super(...arguments);

    this.$parentForm = $(element);

    this.$province = $(this.$parentForm.find('.provinceFieldInput')[0]);

    this.$region = $(this.$parentForm.find('.regionFieldInput')[0]);

    this.defaultStateId = this.$region.data('default-state-id');

    this.updateRowVisibility();
    this.bindEvents();
  }

  bindEvents() {
    this.onStateChanged = this.onStateChanged.bind(this);

    this.$province.on('input', this.onStateChanged);
  }

  unbindEvents() {
    this.$province.off('change');
  }

  onStateChanged() {
    this.updateRowVisibility();
  }

  updateRowVisibility() {
    if (this.$province[0] && this.$region[0]) {
      const regionDropdown = this.$region.parents('.regionField').first();
      if (this.$province[0].value === this.defaultStateId.toString()) {
        regionDropdown.show();
      } else {
        regionDropdown.hide();
        this.$region[0].value = '';
      }
    }
  }
}
