export default class StringHelper {
  static toCamelCase(string) {
    return string.replace(/(_[a-z])/g, (match) => {
      return match.toUpperCase().replace('_', '');
    });
  }

  static capitalize(string) {
    return string.toLowerCase().replace(/(\b)([a-zA-Z])/, (firstLetter) => {
      return firstLetter.toUpperCase();
    });
  }

  static replaceTokens(string, tokens) {
    return Object.keys(tokens).reduce((memo, token) => {
      return memo.replace(token, tokens[token]);
    }, string);
  }
}
