import Component from '../component';

const appID = window.Academos ? window.Academos.FacebookAppID : null;
const authPath = window.Academos
  ? window.Academos.FacebookUserAuthCallbackPath
  : '/';
const loginScope = 'email';
const graphAPIVersion = 'v3.2';

export default class FacebookLogin extends Component {
  constructor(element) {
    super(...arguments);

    this.$element = $(element);

    window.fbAsyncInit = function () {
      window.FB.init({
        appId: appID,
        version: graphAPIVersion,
        cookie: true,
      });
    };

    this.bindEvents();
  }

  bindEvents() {
    this.handleClick = this.handleClick.bind(this);

    this.$element.on('click.FacebookLogin', this.handleClick);
  }

  unbindEvents() {
    this.$element.off('click.FacebookLogin', this.handleClick);
  }

  handleClick() {
    window.FB.getLoginStatus((response) => {
      if (response.authResponse) {
        this.redirectToAuthPath();
      } else {
        this.loginToFacebook();
      }
    });
  }

  loginToFacebook() {
    window.FB.login(
      (response) => {
        if (response.authResponse) {
          this.redirectToAuthPath();
        }
      },
      {scope: loginScope}
    );
  }

  redirectToAuthPath() {
    window.location = authPath;
  }
}
