import Component from '../../shared/component';

export default class ToggleOtherMenteeHeardAboutUsCategory extends Component {
  constructor(element) {
    super(...arguments);

    this.$element = $(element);

    this.$toggleFieldElements = this.$element.find(
      '.toggleHeardAboutUsCategory-field'
    );
    this.$trigger = this.$element.find('.toggleHeardAboutUsCategory-trigger');

    this.bindEvents();
    this.handleChange();
  }

  bindEvents() {
    this.handleChange = this.handleChange.bind(this);
    this.$element.on(
      'change.ToggleOtherMenteeHeardAboutUsCategory',
      this.handleChange
    );
  }

  unbindEvents() {
    this.$element.off(
      'change.ToggleOtherMenteeHeardAboutUsCategory',
      this.handleChange
    );
  }

  handleChange() {
    this.$toggleFieldElements.toggle(this.$trigger.is(':checked'));
  }
}
