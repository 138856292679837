import Component from '../component';

export default class AssociationsField extends Component {
  constructor(element) {
    super(...arguments);

    this.$element = $(element);

    this.$inputElement = this.$element.find('.associationsField-input');
    this.$choicesElement = this.$element.find('.associationsField-choices');
    this.$suggestionItemTemplate = this.$element.find(
      '.associationsField-suggestionTemplate'
    );
    this.$freeTextSuggetionItemTemplate = this.$element.find(
      '.associationsField-freeTextSuggestionTemplate'
    );
    this.$choiceTemplate = this.$element.find(
      '.associationsField-choiceTemplate'
    );
    this.$freeTextChoiceTemplate = this.$element.find(
      '.associationsField-freeTextChoiceTemplate'
    );

    this.bindEvents();

    this.$typeaheadElement = this.$element.find('.twitter-typeahead');
  }

  fetchDOMChoices() {
    return this.$element.find('.associationsField-choices');
  }

  bindEvents() {
    this.bindDynamicEvents();
    this.createBootstrapComponents();

    this.handleAutoCompleteSelect = this.handleAutoCompleteSelect.bind(this);

    this.typeaheadInstance.on(
      'typeahead:selected',
      this.handleAutoCompleteSelect
    );
  }

  unbindEvents() {
    this.typeaheadInstance.off(
      'typeahead:selected',
      this.handleAutoCompleteSelect
    );

    this.unbindDynamicEvents();
    this.destroyBootstrapComponents();
  }

  bindDynamicEvents() {
    this.unbindDynamicEvents();

    this.handleChoiceRemoval = this.handleChoiceRemoval.bind(this);

    this.fetchDOMChoices()
      .find('.associationsField-choice-removal')
      .on('click', this.handleChoiceRemoval);
  }

  unbindDynamicEvents() {
    this.fetchDOMChoices()
      .find('.associationsField-choice-removal')
      .off('click', this.handleChoiceRemoval);
  }

  createBootstrapComponents() {
    const typeaheadOptions = {hint: false, highlight: true};

    this.typeaheadInstance = this.$inputElement.typeahead(
      typeaheadOptions,
      this.getTypeaheadSourceOptions()
    );
  }

  destroyBootstrapComponents() {
    this.$inputElement.typeahead('destroy');
  }

  buildOrganizationSuggestionTemplate(item) {
    const selectedOrganizationIDs = this.fetchSelectedOrganizationIDs();

    if (!selectedOrganizationIDs.includes(item.id.toString())) {
      this.preloadImage(item.photo.url);

      const newHTML = this.$suggestionItemTemplate.clone().html();
      const suggestionItem = newHTML
        .replace(/__name__/g, item.name)
        .replace(/data-src/g, 'src')
        .replace(/__photo_url__/g, item.photo.url);

      return suggestionItem;
    }
  }

  buildFreeTextOrganizationSuggestionTemplate(item) {
    const selectedFreeTextOrganizationNames = this.fetchSelectedFreeTextOrganizationNames();

    if (!selectedFreeTextOrganizationNames.includes(item.name)) {
      const newHTML = this.$freeTextSuggetionItemTemplate.clone().html();
      const suggestionItem = newHTML.replace(/__name__/g, item.name);

      return suggestionItem;
    }
  }

  buildSuggestionTemplate(item) {
    if (item.freeText) {
      return this.buildFreeTextOrganizationSuggestionTemplate(item);
    } else {
      return this.buildOrganizationSuggestionTemplate(item);
    }
  }

  fetchSelectedOrganizationIDs() {
    return this.$choicesElement
      .find('.associationsField-choice-organizationId')
      .map(function () {
        return $(this).val();
      })
      .toArray();
  }

  fetchSelectedFreeTextOrganizationNames() {
    return this.$choicesElement
      .find('.associationsField-choice-freeTextOrganizationName')
      .map(function () {
        return $(this).val();
      })
      .toArray();
  }

  handleOrganizationAutocompleteSelect(selection) {
    const newHTML = this.$choiceTemplate.clone().html();
    const choiceElement = newHTML
      .replace(/__name__/g, selection.name)
      .replace(/input-name/g, 'name')
      .replace(/__organization_id__/g, selection.id)
      .replace(/data-src/g, 'src')
      .replace(/__photo_url__/g, selection.photo.url);

    return choiceElement;
  }

  handleFreeTextOrganizationAutocompleteSelect(selection) {
    const newHTML = this.$freeTextChoiceTemplate.clone().html();
    const choiceElement = newHTML
      .replace(/__name__/g, selection.name)
      .replace(/input-name/g, 'name')
      .replace(/__association_name__/g, selection.name);

    return choiceElement;
  }

  handleAutoCompleteSelect(_, selection) {
    if (selection.photo) this.preloadImage(selection.photo.url);
    this.removeEmptyAssociationsValue();

    const choiceElement = selection.freeText
      ? this.handleFreeTextOrganizationAutocompleteSelect(selection)
      : this.handleOrganizationAutocompleteSelect(selection);

    this.$choicesElement.append(choiceElement);
    this.$choicesElement.removeClass('hidden');
    this.$inputElement.typeahead('val', '');

    this.bindDynamicEvents();
  }

  removeEmptyAssociationsValue() {
    this.$choicesElement.find('.associationsField-choice.hidden').remove();
  }

  addEmptyAssociationsValue(newHTML) {
    let choiceElement = newHTML
      .replace(/__name__/g, '')
      .replace(/input-name/g, 'name')
      .replace(/__association_name__/g, '')
      .replace(/__organization_id__/g, '');

    choiceElement = $(choiceElement).addClass('hidden');
    this.$choicesElement.append(choiceElement);
  }

  handleChoiceRemoval(event) {
    $(event.currentTarget).closest('.associationsField-choice').remove();

    if (
      this.$choicesElement.find('.associationsField-choice--association')
        .length <= 1
    ) {
      const newHTML = this.$choiceTemplate.clone().html();
      this.addEmptyAssociationsValue(newHTML);
    }

    if (
      this.$choicesElement.find('.associationsField-choice--freeText').length <=
      1
    ) {
      const newHTML = this.$freeTextChoiceTemplate.clone().html();
      this.addEmptyAssociationsValue(newHTML);
    }
  }

  preloadImage(src) {
    const image = new Image();

    image.src = src;
  }

  buildFreeTextAssociationFromQuery(query) {
    return {
      name: query,
      freeText: true,
    };
  }

  filterRemoteTags(parsedResponse, query) {
    const responseOrganizations = parsedResponse.organizations;
    responseOrganizations.push(this.buildFreeTextAssociationFromQuery(query));

    return responseOrganizations;
  }

  getTypeaheadSource() {
    const source = new window.Bloodhound({
      datumTokenizer: window.Bloodhound.tokenizers.whitespace('name'),
      queryTokenizer: window.Bloodhound.tokenizers.whitespace,
      limit: Infinity,
      remote: {
        wildcard: '%QUERY',
        url: `${this.$element.data('url')}?q=%QUERY`,
        filter: (parsedResponse) =>
          this.filterRemoteTags(parsedResponse, this.$inputElement.val()),
      },
    });

    source.initialize();

    return source;
  }

  getTypeaheadSourceOptions() {
    const source = this.getTypeaheadSource();

    return {
      name: 'states',
      displayKey: 'name',
      source: source.ttAdapter(),
      templates: {
        suggestion: (item) => this.buildSuggestionTemplate(item),
      },
    };
  }
}
