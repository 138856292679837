import Component from '../component';

export default class OrganizationField extends Component {
  constructor(element) {
    super(...arguments);

    this.$element = $(element);

    this.$inputElement = this.$element.find('.organizationField-input');
    this.$idInputElement = this.$element.find('.organizationField-idInput');
    this.$choiceElement = this.$element.find('.organizationField-choice');
    this.$choiceRemovalElement = this.$element.find(
      '.organizationField-choice-removal-icon'
    );
    this.$suggestionItemTemplate = this.$element.find(
      '.organizationField-suggestionTemplate'
    );

    this.bindEvents();

    this.$typeaheadElement = this.$element.find('.twitter-typeahead');

    if (this.$idInputElement.val()) {
      this.$typeaheadElement.hide();
    }
  }

  bindEvents() {
    this.createBootstrapComponents();

    this.handleChoiceRemoval = this.handleChoiceRemoval.bind(this);
    this.handleAutoCompleteSelect = this.handleAutoCompleteSelect.bind(this);

    this.$choiceRemovalElement.on(
      'click.OrganizationField',
      this.handleChoiceRemoval
    );
    this.typeaheadInstance.on(
      'typeahead:selected',
      this.handleAutoCompleteSelect
    );
  }

  unbindEvents() {
    this.$choiceRemovalElement.off(
      'click.OrganizationField',
      this.handleChoiceRemoval
    );
    this.typeaheadInstance.off(
      'typeahead:selected',
      this.handleAutoCompleteSelect
    );

    this.destroyBootstrapComponents();
  }

  createBootstrapComponents() {
    const typeaheadOptions = {hint: false, highlight: true};

    this.typeaheadInstance = this.$inputElement.typeahead(
      typeaheadOptions,
      this.getTypeaheadSourceOptions()
    );
  }

  destroyBootstrapComponents() {
    this.$inputElement.typeahead('destroy');
  }

  getTypeaheadSourceOptions() {
    const source = this.getTypeaheadSource();

    return {
      name: 'states',
      displayKey: 'name',
      source: source.ttAdapter(),
      templates: {
        suggestion: (item) => this.buildSuggestionTemplate(item),
      },
    };
  }

  getTypeaheadSource() {
    const source = new window.Bloodhound({
      datumTokenizer: window.Bloodhound.tokenizers.whitespace('name'),
      queryTokenizer: window.Bloodhound.tokenizers.whitespace,
      limit: Infinity,
      remote: {
        wildcard: '%QUERY',
        url: `${this.$element.data('url')}?q=%QUERY`,
        filter: (parsedResponse) => this.filterRemoteTags(parsedResponse),
      },
    });

    source.initialize();

    return source;
  }

  buildSuggestionTemplate(item) {
    this.preloadImage(item.photo.url);

    const newHTML = this.$suggestionItemTemplate.clone().html();
    const suggestionItem = newHTML
      .replace(/__name__/g, item.name)
      .replace(/data-src/g, 'src')
      .replace(/__photo_url__/g, item.photo.url);

    return suggestionItem;
  }

  preloadImage(src) {
    const image = new Image();

    image.src = src;
  }

  filterRemoteTags(parsedResponse) {
    return parsedResponse.organizations;
  }

  handleAutoCompleteSelect(_, selection) {
    // Set organization ID in the second input
    this.$idInputElement.val(selection.id);
    this.$inputElement.addClass('hidden');

    // Hide typeahead instance
    this.$typeaheadElement.hide();

    // Display result
    this.$choiceElement
      .find('.organizationField-choice-logo-image')
      .attr('src', selection.photo.url);
    this.$choiceElement
      .find('.organizationField-choice-name')
      .html(selection.name);
    this.$choiceElement.removeClass('hidden');
  }

  handleChoiceRemoval() {
    // Hide result
    this.$choiceElement.addClass('hidden');

    // Display typeahead instance
    this.$typeaheadElement.show();

    // Reset organization ID in input
    this.$inputElement.removeClass('hidden');
    this.$inputElement.val('');
    this.$idInputElement.val('');
    this.$inputElement.focus();
  }
}
