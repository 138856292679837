import Component from '../component';
import StringHelper from '../../helpers/string-helper';

export const ENTER_KEYCODE = 13;

export default class TagField extends Component {
  constructor(element) {
    super(...arguments);

    const DEFAULT_MIN_LENGTH = 3;

    this.$element = $(element);
    this.$formElement = this.$element.closest('form');
    this.url = this.$element.data('url');

    this.maxTags = this.$element.data('max-tags');
    this.disallowAddTag = this.$element.data('disallow-add-tag');
    this.emptyLabel = this.$element.data('empty-label') || null;
    this.searchMinLength =
      this.$element.data('search-min-length') || DEFAULT_MIN_LENGTH;

    this.createBootstrapComponents();
    this.bindEvents();
  }

  bindEvents() {
    this.handleAutoCompleteSelect = this.handleAutoCompleteSelect.bind(this);
    this.handleTagAdd = this.handleTagAdd.bind(this);
    this.handleTagRemove = this.handleTagRemove.bind(this);
    this.handleFieldBlur = this.handleFieldBlur.bind(this);

    this.typeaheadInstance.on(
      'typeahead:selected',
      this.handleAutoCompleteSelect
    );
    this.$element.on('itemAdded', this.handleTagAdd);
    this.$element.on('itemRemoved', this.handleTagRemove);

    // Unbind Typeahead’s own blur event because we need its value before we
    // clear the field.
    this.typeaheadInstance.off('blur.tt');
    this.typeaheadInstance.on('blur.TagField', this.handleFieldBlur);
  }

  unbindEvents() {
    this.typeaheadInstance.off(
      'typeahead:selected',
      this.handleAutoCompleteSelect
    );
    this.$element.off('itemAdded', this.handleTagAdd);
    this.$element.off('itemRemoved', this.handleTagRemove);
    this.typeaheadInstance.off('blur.TagField', this.handleFieldBlur);

    this.$tagsInputElement.off('keydown.TagField', this.handleChange);

    this.destroyBootstrapComponents();
  }

  createBootstrapComponents() {
    const COMMA_KEYCODE = 188;
    const TAB_KEYCODE = 9;

    let options = {
      tagClass: () => 'tagField-tag',
      itemText: (item) => this.sanitizeItemText(item),
      confirmKeys: [ENTER_KEYCODE, COMMA_KEYCODE, TAB_KEYCODE],
      freeInput: !this.disallowAddTag,
    };

    if (this.maxTags) {
      options = {...options, maxTags: this.maxTags};
    }

    // First, initialize the tagsinput component
    this.$element.tagsinput(options);

    this.$tagsInputElement = this.$element
      .tagsinput('input')
      .closest('.bootstrap-tagsinput');

    this.handleChange = this.handleChange.bind(this);

    this.$tagsInputElement.on('keydown.TagField', this.handleChange);

    // Next, initialize the typeahead component on the newly created tagsinput field
    const typeaheadOptions = {
      hint: false,
      highlight: true,
      minLength: this.searchMinLength,
    };
    this.typeaheadInstance = this.$element
      .tagsinput('input')
      .typeahead(typeaheadOptions, this.getTypeaheadSourceOptions());

    if (this.$element.attr('autofocus')) {
      setTimeout(() => this.autoFocusInput(), 100);
    }
  }

  destroyBootstrapComponents() {
    this.$element.tagsinput('input').typeahead('destroy');
    this.$element.tagsinput('destroy');
  }

  // Typeahead + Bloodhound

  getTypeaheadSourceOptions() {
    const source = this.getTypeaheadSource();

    return {
      name: 'states',
      displayKey: 'name',
      source: source.ttAdapter(),
      templates: {
        suggestion: (item) => `<a class="needsclick">${item.name}</a>`,
        empty: () => {
          if (this.emptyLabel === null) return;

          return `<div class="empty-result">${this.emptyLabel}</div>`;
        },
      },
    };
  }

  getTypeaheadSource() {
    const source = new window.Bloodhound({
      datumTokenizer: window.Bloodhound.tokenizers.whitespace('name'),
      queryTokenizer: window.Bloodhound.tokenizers.whitespace,
      remote: {
        wildcard: '%QUERY',
        url: `${this.$element.data('url')}?q=%QUERY`,
        filter: (parsedResponse) => this.filterRemoteTags(parsedResponse),
      },
    });

    source.initialize();

    return source;
  }

  filterRemoteTags(parsedResponse) {
    if (parsedResponse.tags) {
      return parsedResponse.tags;
    }

    return parsedResponse;
  }

  sanitizeItemText(item) {
    const MAX_LENGTH = 50;
    const TRUNCATED_LENGTH = 40;

    const text = item ? StringHelper.capitalize(item) : item;

    if (text.length > MAX_LENGTH) {
      return `${text.substr(0, TRUNCATED_LENGTH)}…`;
    } else {
      return text;
    }
  }

  autoFocusInput() {
    this.$element.tagsinput('input').focus();
  }

  addTag(tagValue) {
    this.$element.tagsinput('add', tagValue);
    this.resetInputValue();
  }

  resetInputValue() {
    this.$element.tagsinput('input').typeahead('val', '');
  }

  handleAutoCompleteSelect(_, datum) {
    this.addTag(datum.name);
  }

  handleTagAdd() {
    // Hide the input if maxTags is reached
    if (
      this.maxTags &&
      this.maxTags <= this.$tagsInputElement.find('.tag').length
    ) {
      this.$tagsInputElement.find('.tt-input').hide();
    }

    this.resetInputValue();
  }

  handleTagRemove() {
    // Show the input if maxTags is reached
    if (
      this.maxTags &&
      this.maxTags > this.$tagsInputElement.find('.tag').length
    ) {
      this.$tagsInputElement.find('.tt-input').show();
    }
  }

  handleFieldBlur() {
    if (this.disallowAddTag) {
      this.typeaheadInstance.typeahead('val', '');
      return;
    }

    this.addTag(this.typeaheadInstance.val());
    this.resetInputValue();
  }

  handleChange(event) {
    if (this.disallowAddTag && event.keyCode === ENTER_KEYCODE) {
      event.preventDefault();
      return;
    }

    const MULTIPLIER = 0.2;
    const MAX_SIZE_ADDITION = 5;

    const $input = this.$element.tagsinput('input');
    // NOTE : `+ Math.max(Math.floor($input.val().length * 0.2), 5)` is used to correct a glitch
    // on iOS where even with only 2 letters typed, we often don’t see them because the `size` of the field is to low
    const size =
      $input.val().length +
      Math.max(Math.floor($input.val().length * MULTIPLIER), MAX_SIZE_ADDITION);

    $input.attr('size', size);
  }
}
