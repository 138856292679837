import Component from '../component';

export default class DynamicDropdown extends Component {
  constructor(element) {
    super(...arguments);

    this.$element = $(element);
    this.$parentTarget = $(this.$element.data('dynamic-target'));
    this.$target = this.$parentTarget.find('select');
    this.url = this.$element.data('dynamic-url');

    this.bindEvents();
    this.requestTargetOptions(this.$element.val());
  }

  bindEvents() {
    this.handleChange = this.handleChange.bind(this);

    this.$element.on('change.DynamicDropdown', this.handleChange);
  }

  unbindEvents() {
    this.$element.off('change.DynamicDropdown', this.handleChange);
  }

  handleChange(event) {
    this.requestTargetOptions(event.target.value);
  }

  handleAJAXSuccess(data) {
    if (!data.options.length) return this.disableTargetDropdown();

    this.$parentTarget.removeClass('hidden');
    this.$target.prop('disabled', false);
    this.loadTargetDropdown(data.options);
  }

  requestTargetOptions(value) {
    if (!value) return this.disableTargetDropdown();

    const data = {
      [this.$element.data('dynamic-query-param')]: value,
    };

    $.ajax({url: this.url, data}).done((data) => this.handleAJAXSuccess(data));
  }

  disableTargetDropdown() {
    this.$target.val('');
    this.$parentTarget.addClass('hidden');
    this.$target.prop('disabled', true);
    this.loadTargetDropdown([]);
  }

  loadTargetDropdown(options) {
    const select = this.$target[0];
    const selectedOption = this.$target.find(':selected').val();
    select.innerHTML = '';

    options.forEach((option) => {
      const opt = document.createElement('option');
      opt.value = option.value;
      opt.selected = String(option.value) === selectedOption;
      opt.innerHTML = option.label;
      select.appendChild(opt);
    });
  }
}
