import Component from '../component';

export default class SwipedCards extends Component {
  constructor(element) {
    super(...arguments);

    this.$element = $(element);

    this.$rightStack = this.$element.find('.swipedCards-right');
    this.$lockedAction = $('.swipedCards-lockedActionOnCount');
    this.$instructionText = $('.swipedCards-textInstructionOnCount');
    this.$doneText = $('.swipedCards-textDoneOnCount');
    this.isLockedActionShowing = false;
    this.lockedActionLimit = this.$lockedAction.attr('count-to-unlock') || 0;
    this.lockedActionLimit = parseInt(this.lockedActionLimit, 10);

    this.bindEvents();

    this.updateLockedAction();
  }

  bindEvents() {
    this.handleRightSwiped = this.handleRightSwiped.bind(this);

    this.$element.on('swiped-right.SwipeableCards', this.handleRightSwiped);
  }

  unbindEvents() {
    this.$element.off('swiped-right.SwipeableCards', this.handleRightSwiped);
  }

  handleRightSwiped(_event, value) {
    this.$rightStack.append(`<span>${value}</span>`);
    this.updateLockedAction();
  }

  updateLockedAction() {
    if (this.isLockedActionShowing) return;

    if (this.$rightStack.find('span').length >= this.lockedActionLimit) {
      this.$instructionText.hide();
      this.$doneText.show();
      this.$lockedAction.addClass('swipedCards-lockedActionOnCount--show');
      this.isLockedActionShowing = true;
    }
  }
}
