import Component from '../component';

export default class PhotoUploader extends Component {
  constructor(element) {
    super(...arguments);

    this.$element = $(element);

    this.$input = this.$element.find('.photoUploader-input');
    this.$preview = this.$element.find('.photoUploader-image');

    this.bindEvents();
  }

  bindEvents() {
    this.updatePreview = this.updatePreview.bind(this);
    this.$input.on('change', this.updatePreview);
  }

  unbindEvents() {
    this.$input.off('change', this.updatePreview);
  }

  updatePreview() {
    this.$preview.attr(
      'src',
      URL.createObjectURL(this.$input.prop('files')[0])
    );
  }
}
