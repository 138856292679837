import Component from '../component';

export default class MaxSizeFileField extends Component {
  constructor(element) {
    super(...arguments);

    this.$element = $(element);
    this.maxSize = parseInt(this.$element.data('max-size'), 10);
    this.maxSizeMessage = this.$element.data('max-size-message');

    this.bindEvents();
  }

  bindEvents() {
    this.handleFieldChange = this.handleFieldChange.bind(this);

    this.$element.on('change.MaxSizeFileField', this.handleFieldChange);
  }

  unbindEvents() {
    this.$element.off('change.MaxSizeFileField', this.handleFieldChange);
  }

  handleFieldChange(event) {
    if (window.FileReader) {
      const file = event.currentTarget.files[0];

      if (file.size > this.maxSize) {
        // eslint-disable-next-line no-alert
        window.alert(this.maxSizeMessage);
        this.$element.val('');
      }
    }
  }
}
