import Component from '../component';

export default class TilesSelector extends Component {
  constructor(element) {
    super(...arguments);

    this.$element = $(element);
    this.$form = this.$element.parent('form');
    this.$tiles = this.$element.find('.tilesSelector-tile');
    this.$select = this.$element.find('.tilesSelector-select');

    this.bindEvents();
  }

  bindEvents() {
    this.handleSelect = this.handleSelect.bind(this);

    this.$tiles.on('click.TilesSelector', this.handleSelect);
  }

  unbindEvents() {
    this.$tiles.off('click.TilesSelector', this.handleSelect);
  }

  handleSelect(event) {
    let $target = $(event.target);

    if (!$target.hasClass('tilesSelector-tile')) {
      $target = $(event.target).parent('.tilesSelector-tile');
    }

    if ($target === this.$selectedTile) return;

    const value = $target.attr('value');

    this.selectTile($target);
    this.$select.val(value);
    this.$form.submit();
  }

  selectTile(target) {
    this.$selectedTile = target;

    this.$tiles.removeClass('tilesSelector-tile--active');
    target.addClass('tilesSelector-tile--active');
  }
}
