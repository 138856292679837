import {Stack, Direction} from 'swing';

import Component from '../component';

const ALERT_SHOW_DURATION = 2000;
const BUTTON_HIGHLIGHT_DURATION = 500;
const BUTTON_THROTTLE_DISABLE_DURATION = 200;
const DISABLE_BUTTON_ON_DRAG_DURATION = 200;

const SWING_STACK_CONFIG = {
  /**
   * Invoked in the event of dragmove.
   * Returns a value between 0 and 1 indicating the completeness of the throw out condition.
   * Ration of the absolute distance from the original card position and element width divided by 2.
   *
   * This makes the card "go away" when it is passed the middle of the stack.
   */
  throwOutConfidence: (offsetX, _offsetY, element) => {
    return Math.min(Math.abs(offsetX) / (element.offsetWidth / 2), 1);
  },

  /**
   * The distance, in pixel, of the offset of the card when thrown out of the stack.
   * The default value did not prevent huge offset when used on a small viewport.
   *
   * The maximum of 500 comes from the max-width of the stack. The offset will never
   * be to far from the stack.
   */
  throwOutDistance: () => {
    const THROW_OUT_THRESHOLD = 500;

    return Math.min(window.innerWidth - 100, THROW_OUT_THRESHOLD);
  },
};

export default class SwipeableCards extends Component {
  constructor(element) {
    super(...arguments);

    this.$element = $(element);

    this.stack = Stack(SWING_STACK_CONFIG);
    this.$stack = this.$element.find('.swipeableCards-stack');
    this.$actions = this.$element.find('.swipeableCards-actions');
    this.$swiped = this.$element.find('.swipedCards');
    this.$cards = this.$element
      .find('.swipeableCards-item')
      .not('.swipeableCards-item--empty');

    // Action buttons
    this.$swipeButtons = this.$element.find('.swipeableCards-actions-item');
    this.$swipeLeftButton = this.$element.find(
      '.swipeableCards-actions-item--swipeLeft'
    );
    this.$swipeRightButton = this.$element.find(
      '.swipeableCards-actions-item--swipeRight'
    );

    // Flash messages
    this.$successRightAlert = this.$element.find(
      '.swipeableCards-right-flash--notice'
    );
    this.$errorRightAlert = this.$element.find(
      '.swipeableCards-right-flash--alert'
    );
    this.$successLeftAlert = this.$element.find(
      '.swipeableCards-left-flash--notice'
    );
    this.$errorLeftAlert = this.$element.find(
      '.swipeableCards-left-flash--alert'
    );

    this.bindEvents();
  }

  bindEvents() {
    this.adjustCardStackHeight();
    this.initializeCardStack();
    this.initializeStackActionButtons();
  }

  unbindEvents() {
    this.$swipeLeftButton.off('click', this.handleLeftButtonClick);
    this.$swipeRightButton.off('click', this.handleRightButtonClick);

    this.$cards.toArray().forEach((targetElement) => {
      const card = this.stack.getCard(targetElement);

      $(targetElement).off('mouseleave', this.handleCardLeave);

      if (!card) return;

      card.off('dragmove', this.handleCardDragMove);
      card.off('dragend', this.handleCardDragEnd);
    });
  }

  adjustCardStackHeight() {
    const cardHeight = $(this.$cards[0]).outerHeight();

    this.$stack.height(cardHeight);
  }

  initializeStackActionButtons() {
    this.handleLeftButtonClick = this.handleButtonClick.bind(
      this,
      'clickedLeft',
      Direction.LEFT
    );
    this.handleRightButtonClick = this.handleButtonClick.bind(
      this,
      'clickedRight',
      Direction.RIGHT
    );

    this.$swipeLeftButton.on('click', this.handleLeftButtonClick);
    this.$swipeRightButton.on('click', this.handleRightButtonClick);
  }

  initializeCardStack() {
    this.handleCardLeave = this.handleCardLeave.bind(this);
    this.handleCardDragMove = this.handleCardDragMove.bind(this);
    this.handleCardDragEnd = this.handleCardDragEnd.bind(this);
    this.handleCardThrowoutEnd = this.handleCardThrowoutEnd.bind(this);
    this.handleCardThrowout = this.handleCardThrowout.bind(this);

    this.cardsCount = this.$cards.toArray().length;

    this.$cards.toArray().forEach((targetElement) => {
      const card = this.stack.createCard(targetElement);

      $(targetElement).on('mouseleave', this.handleCardLeave);
      card.on('dragmove', this.handleCardDragMove);
      card.on('dragend', this.handleCardDragEnd);
    });

    this.stack.on('throwoutend', this.handleCardThrowoutEnd);
    this.stack.on('throwout', this.handleCardThrowout);
  }

  handleCardLeave() {
    setTimeout(() => {
      this.$swipeButtons.removeClass('disabled');
    }, DISABLE_BUTTON_ON_DRAG_DURATION);
  }

  handleCardDragMove(event) {
    this.$swipeButtons.addClass('disabled');
    $(event.target).addClass('drag');
  }

  handleCardDragEnd(event) {
    $(event.target).removeClass('drag');

    setTimeout(() => {
      this.$swipeButtons.removeClass('disabled');
    }, DISABLE_BUTTON_ON_DRAG_DURATION);
  }

  handleCardThrowout(event) {
    this.cardsCount = this.cardsCount - 1;

    if (event.throwDirection === Direction.RIGHT) {
      this.handleSwipeRight(event.target);
    } else {
      this.handleSwipeLeft(event.target);
    }

    if (this.cardsCount <= 0) this.reloadMoreCards();
  }

  reloadMoreCards() {
    window.Turbolinks.reload({keepScrollPosition: true});
  }

  handleCardThrowoutEnd(event) {
    const card = this.stack.getCard(event.target);

    $(event.target).fadeOut(() => card.destroy());
  }

  handleButtonClick(className, direction) {
    if (this.$swipeButtons.hasClass('disabled')) return;

    const cardElement = this.$cards[this.cardsCount - 1];
    const card = this.stack.getCard(cardElement);

    if (card) {
      const $cardElement = $(cardElement);

      $cardElement.parent('.swipeableCards-itemWrapper').addClass(className);

      // Fake a throwout here just so that the same logic applies
      // when we like/dislike an item with a click
      this.handleCardThrowout({
        target: $cardElement.get(0),
        throwDirection: direction,
      });
      this.handleCardThrowoutEnd({target: $cardElement.get(0)});
    }
  }

  handleSwipeRight(card) {
    this.$actions.addClass('swipeableCards-actions--read-only');
    this.animateButton(this.$swipeRightButton);
    const value = $(card).attr('swipe-value');

    if (value) {
      this.$swiped.trigger('swiped-right.SwipeableCards', value);
    }

    const url = $(card).attr('swipe-right-action');

    $.post(url)
      .done(() => this.handleSwipeRightSuccess())
      .fail(() => this.handleSwipeRightError())
      .always(() => this.handleSwipeComplete());
  }

  handleSwipeRightSuccess() {
    this.showAlert(this.$successRightAlert);
  }

  handleSwipeRightError() {
    this.showAlert(this.$errorRightAlert);
  }

  handleSwipeLeft(card) {
    this.$actions.addClass('swipeableCards-actions--read-only');
    this.animateButton(this.$swipeLeftButton);

    const url = $(card).attr('swipe-left-action');

    $.post(url)
      .done(() => this.handleSwipeLeftSuccess())
      .fail(() => this.handleSwipeLeftError())
      .always(() => this.handleSwipeComplete());
  }

  handleSwipeLeftSuccess() {
    this.showAlert(this.$successLeftAlert);
  }

  handleSwipeLeftError() {
    this.showAlert(this.$errorLeftAlert);
  }

  handleSwipeComplete() {
    setTimeout(() => {
      this.$actions.removeClass('swipeableCards-actions--read-only');
    }, BUTTON_THROTTLE_DISABLE_DURATION);
  }

  animateButton(element) {
    if (this.$element.data('animate-buttons')) {
      element.addClass('highlight');

      setTimeout(() => {
        element.removeClass('highlight');
      }, BUTTON_HIGHLIGHT_DURATION);
    }
  }

  showAlert(element) {
    const newElement = element.clone();
    newElement.removeClass('hidden');
    newElement.appendTo(this.$stack);

    setTimeout(() => {
      newElement.fadeOut(() => newElement.remove());
    }, ALERT_SHOW_DURATION);
  }
}
