import Component from '../../shared/component';

export default class OrganizationBillingCoordinatesForm extends Component {
  constructor(element) {
    super(...arguments);

    this.$element = $(element);

    this.$trigger = this.$element.find('#use_public_coordinates');

    // Fields to use
    this.$publicStreetAddress = this.$element.find(
      '#hidden_public_coordinates_street_address'
    );
    this.$publicStreetAddressApt = this.$element.find(
      '#hidden_public_coordinates_street_address_apt'
    );
    this.$publicPostalCode = this.$element.find(
      '#hidden_public_coordinates_postal_code'
    );
    this.$publicCity = this.$element.find('#hidden_public_coordinates_city');
    this.$publicStateId = this.$element.find(
      '#hidden_public_coordinates_state_id'
    );
    this.$publicCountry = this.$element.find(
      '#hidden_public_coordinates_country'
    );

    // Fields to fill if trigger is checked
    this.$billingStreetAddress = this.$element.find(
      '#billing_coordinates_street_address'
    );
    this.$billingStreetAddressApt = this.$element.find(
      '#billing_coordinates_street_address_apt'
    );
    this.$billingPostalCode = this.$element.find(
      '#billing_coordinates_postal_code'
    );
    this.$billingCity = this.$element.find('#billing_coordinates_city');
    this.$billingStateId = this.$element.find('#billing_coordinates_state_id');
    this.$billingCountry = this.$element.find('#billing_coordinates_country');

    this.bindEvents();
  }

  bindEvents() {
    this.handleChange = this.handleChange.bind(this);

    this.$trigger.on(
      'change.OrganizationBillingCoordinatesForm',
      this.handleChange
    );
  }

  unbindEvents() {
    this.$trigger.off(
      'change.OrganizationBillingCoordinatesForm',
      this.handleChange
    );
  }

  handleChange() {
    const fieldsToFill = [
      this.$billingStreetAddress,
      this.$billingStreetAddressApt,
      this.$billingPostalCode,
      this.$billingCity,
      this.$billingStateId,
      this.$billingCountry,
    ];

    const fieldsToUse = [
      this.$publicStreetAddress,
      this.$publicStreetAddressApt,
      this.$publicPostalCode,
      this.$publicCity,
      this.$publicStateId,
      this.$publicCountry,
    ];

    fieldsToFill.forEach(($field, index) => {
      if (this.$trigger.is(':checked')) {
        $field.attr('disabled', true);
        $field.val(fieldsToUse[index].val());
      } else {
        $field.attr('disabled', false);
        $field.val('');
      }
    });
  }
}
