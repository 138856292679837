import Component from '../component';

// @class TypeaheadInput
// @param element {String|jQuery} input element of the component
// @param opts {Object} options passed directly to `typeahead`
export default class TypeaheadInput extends Component {
  constructor(element, opts) {
    super(...arguments);

    this.$typeaheadInput = $(element);

    this.typeaheadOptions = opts;

    this.bindEvents();
  }

  bindEvents() {
    this.typeahead = this.$typeaheadInput.typeahead(
      this.typeaheadOptions,
      this.getTypeaheadSources()
    );

    this.typeahead.on('typeahead:close', this.handleTypeaheadClose.bind(this));
    this.typeahead.on(
      'typeahead:select',
      this.handleTypeaheadSelect.bind(this)
    );
  }

  unbindEvents() {
    this.typeahead.off('typeahead:close', this.handleTypeaheadClose);
    this.typeahead.off('typeahead:select', this.handleTypeaheadSelect);

    this.$typeaheadInput.typeahead('destroy');
  }

  handleTypeaheadClose() {
    // Subclasses must implement this method
  }

  handleTypeaheadSelect() {
    // Subclasses must implement this method
  }

  getTypeaheadSources() {
    // Subclasses must implement this method
  }

  createTypeaheadSource(url, callback) {
    return new window.Bloodhound({
      datumTokenizer: window.Bloodhound.tokenizers.whitespace('name'),
      queryTokenizer: window.Bloodhound.tokenizers.whitespace,
      limit: Infinity,
      remote: {
        wildcard: '%QUERY',
        url: `${url}?q=%QUERY`,
        filter: callback,
      },
    });
  }
}
