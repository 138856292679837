import Component from '../component';

const DATEPICKER_ATTRIBUTES = {
  format: 'yyyy-mm-dd',
  todayBtn: true,
  language: 'fr',
  autoclose: true,
  todayHighlight: true,
  disableTouchKeyboard: true,
};

export default class DatePicker extends Component {
  constructor(element) {
    super(...arguments);

    this.$element = $(element);
    this.$element.addClass('needsclick');
    this.attributes = {
      startView: this.$element.data('start-view'),
      startDate: this.$element.data('start-date'),
      endDate: this.$element.data('end-date'),
      todayHighlight: this.$element.data('today-highlight'),
    };

    this.bindEvents();
  }

  bindEvents() {
    const attributes = {...DATEPICKER_ATTRIBUTES, ...this.attributes};
    this.$element.datepicker(attributes);
  }

  unbindEvents() {
    this.$element.datepicker('destroy');
  }
}
