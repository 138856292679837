import ComponentManager from '../shared/component-manager';
import ArrayField from '../shared/components/array-field';
import ArrayFieldCocoon from '../shared/components/array-field-cocoon';
import BirthDatePicker from '../shared/components/birth-date-picker';
import DatePicker from '../shared/components/date-picker';
import DropdownSelect from '../shared/components/dropdown-select';
import DynamicDropdown from '../shared/components/dynamic-dropdown';
import PhotoUploader from '../shared/components/photo-uploader';
import TagField from '../shared/components/tag-field';
import MaxSizeFileField from '../shared/components/max-size-file-field';
import OrganizationField from '../shared/components/organization-field';
import AssociationsField from '../shared/components/associations-field';
import OtherOccupationField from '../shared/components/other-occupation-field';
import SwipeableCards from '../shared/components/swipeable-cards';
import SwipedCards from '../shared/components/swiped-cards';
import TilesSelector from '../shared/components/tiles-selector';
import SchoolField from '../shared/components/school-field';
import OrganizationBillingCoordinatesForm from './components/organization-billing-coordinates-form';
import FacebookLogin from '../shared/components/facebook-login';
import RegionDropdown from '../shared/components/region-dropdown';
import ToggleOtherMenteeHeardAboutUsCategory from './components/toggle-other-mentee-heard-about-us-category';
class LobbyComponentManager extends ComponentManager {
  initializeComponents() {
    ArrayField.initialize('.arrayField');
    ArrayFieldCocoon.initialize('.arrayField--cocoon');
    BirthDatePicker.initialize('.lobbyForm-birth-date-picker');
    DatePicker.initialize('.datePicker');
    SwipeableCards.initialize('.swipeableCards');
    SwipedCards.initialize('.swipedCards');
    DropdownSelect.initialize('.dropdownSelect');
    DynamicDropdown.initialize('.dynamicDropdown');
    PhotoUploader.initialize('.photoUploader');
    TagField.initialize('.tagField');
    TilesSelector.initialize('.tilesSelector');
    MaxSizeFileField.initialize('.maxSizeFileField');
    OrganizationBillingCoordinatesForm.initialize(
      '.organizationBillingCoordinates-form'
    );
    OrganizationField.initialize('.organizationField');
    AssociationsField.initialize('.associationsField');
    SchoolField.initialize('.schoolField');
    OtherOccupationField.initialize('.teacher-occupation-select');
    FacebookLogin.initialize('.lobbyForm-social-item--facebook');
    RegionDropdown.initialize('.search-form-dependency');
    ToggleOtherMenteeHeardAboutUsCategory.initialize(
      '.toggleHeardAboutUsCategory'
    );
  }
}

export const initialize = () => {
  jQuery(() => {
    // Reset the visit tracker in the lobby
    if (window.ahoy) window.ahoy.reset();

    new LobbyComponentManager([
      ArrayField,
      BirthDatePicker,
      DatePicker,
      DropdownSelect,
      DynamicDropdown,
      FacebookLogin,
      MaxSizeFileField,
      OrganizationBillingCoordinatesForm,
      OtherOccupationField,
      PhotoUploader,
      SwipeableCards,
      SwipedCards,
      TagField,
      TilesSelector,
      RegionDropdown,
      ToggleOtherMenteeHeardAboutUsCategory,
    ]);
  });
};
