class ComponentManager {
  constructor(components) {
    this.components = components;
    this.initializeGlobals();
    this.initializeResponsiveWidths();
    this.initializeTurbolinks();
    this.initializeComponents();
    this.bindEvents();
  }

  initializeComponents() {
    throw new Error(
      'Classes that inherit from ComponentManager must implement their own initializeComponents method.'
    );
  }

  initializeGlobals() {
    window.$window = $(window);
    window.$document = $(document);
    window.$html = $('html');
  }

  initializeResponsiveWidths() {
    window.pageContentWidthMd = 991;
    window.pageContentWidthSm = 767;
  }

  initializeTurbolinks() {
    try {
      window.Turbolinks.clearCache();
    } catch (_error) {
      // If it’s the first page load, Turbolinks isn’t started
      // and won’t accept that we call `clearCache`
    }

    window.Turbolinks.start();
  }

  destroyComponents() {
    this.components.forEach((component) => {
      component.unbindEvents();
    });
  }

  simulateOnBeforeUnload() {
    if (window.onbeforeunload && $.isFunction(window.onbeforeunload)) {
      const alertValue = window.onbeforeunload();

      if (alertValue && typeof alertValue !== 'undefined') {
        // eslint-disable-next-line no-alert
        return window.confirm(alertValue);
      }
    }
  }

  resetOnBeforeUnload() {
    window.onbeforeunload = null;
  }

  bindEvents() {
    // Handle individual components
    $(document).on('turbolinks:load', () => this.initializeComponents());
    $(document).on('turbolinks:before-cache', () => this.destroyComponents());

    // Handle window.onbeforeunload behavior
    $(document).on('turbolinks:click', () => this.simulateOnBeforeUnload());
    $(document).on('turbolinks:request-end', () => this.resetOnBeforeUnload());
  }
}

export default ComponentManager;
